.logsign-container {
    position: absolute; /* Ensures it overlaps everything */
    top: 0;
    left: 0;
    z-index: 100; /* Higher than the navbar */
    width: 100%; /* Full width */
    height: 100vh; /* Full height */
    min-width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../images/sunrise_mountains.png'); 
    background-size: cover; 
    background-position: center; 
    background-repeat: no-repeat; 
}




.card-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    width: 50vw;  
    max-width: 600px; 
    padding: 2rem;  
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);  
    border-radius: 0.5rem; 
    border: 1px solid black; /* Adds a black outline with a thickness of 2px */
}


.signup-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20vw;
    min-width: 400px;  
    max-width: 600px;
}

.login-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.input-field {
    width: 100%;  
    max-width: 400px;  
}

.header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 3vh;
}

.header-text {
    font-size: xx-large;
    font-weight: bolder;
}
