.userlist-container {         
    width: calc(100vw - 15vw);
    min-width: calc(100vw - 200px);
    max-width: calc(100vw - 400px);
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}


html, body {
    margin: 0;
    padding: 0;
    height: 100%;
}
