
.center-container-profile {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    min-height: 100vh;
    width: 100%; 
    max-width: 40vw;
    min-width: 300px; 
    margin: auto;
}


/* Header section containing profile picture and info */
.profile-head {
    width: 100%; /* Matches the parent width */
    padding: 20px; /* Spacing around content */
    display: flex; /* Flexbox layout */
    align-items: center; /* Center items vertically */
    justify-content: space-between; /* Distribute space between children */
    max-width: 1500px; /* Restricts maximum width */
    background-color: #fcd8b6; /* Same as main container background */
    border-radius: 2%; /* Rounded corners */
    border: 1.5px solid #8b4513; /* Dark border for definition */
    margin-top: 5px;
    position: relative; /* Set relative positioning for absolute children */
}

/* Username styling */
.username-text {
    font-size: 1.5rem; /* Slightly larger font size */
    font-weight: bold; /* Bold text for emphasis */
    color: #450b00; /* Dark brown text color */
}

/* Profile picture styling */
.pfp {
    width: 4vw;
    min-width: 4vw;
    max-width: 4vw;
    height: 4vw;
    min-height: 4vw;
    max-height: 4vw;
    overflow: hidden; /* Hides content overflowing the circle */
    border: 1.5px solid #8b4513; /* Border for emphasis */
    flex-direction: row; /* Stack items vertically */
}

.box{
    flex-direction: column;
}

.info {
    display: flex;
    flex-direction: column; /* Stack info vertically */
    flex: 1; /* Allow info to take remaining space */
    align-items: flex-start; /* Align items to the left */
    margin-bottom: -40px; /* Space below the username */
}

.username-text {
    font-size: 2rem; /* Adjust font size for username */
    font-weight: bold; /* Make username bold */
    margin-left: 20px;
    flex-direction: column;
}

.settings-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 3.0rem;
    color: #000;
    position: fixed; /* Fixed to the viewport */
    top: 3% !important;
    left: 93%;
}


.info-line {
    display: flex; /* Use flexbox to arrange items in a line */
    justify-content: space-between; /* Space evenly */
    width: 100%; /* Full width */
    margin-top: 10px; /* Space from the username */
    margin-right: 20px;

}

.info-text {
    text-align: center;
    font-weight: bold; /* Make text bold for emphasis */
    cursor: pointer; /* Add this if not already set */
}

.bio {
    font-size: medium;
    text-align: left;
    margin-top: 10px; /* Add margin for spacing */
}

.feed-container-profile {
    display: flex; /* Flexbox for layout */
    flex-direction: column; /* Stack children vertically */
    justify-content: flex-start; /* Align to the top */
    align-items: center; /* Center horizontally */
    width: 100%; /* Full width */
    max-width: 1200px; /* Maximum width */
    margin: 0 auto; /* Center horizontally */
    padding: 20px; /* Internal spacing */
    background-color: #fcd8b6; /* Same as main container background */
    border-radius: 2%; /* Rounded corners */
    border: 1.5px solid #8b4513; /* Dark border for definition */
    
    overflow-y: scroll; /* Enable vertical scrolling */
    scrollbar-width: none; /* Hide scrollbar (for Firefox) */
    overflow-y: scroll; /* Allow vertical scrolling */
}

.feed-bottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch; /* Ensure children match parent width */
    height: 80%;
    min-height: 80vh;
    width: 100%;
    max-width: 40vw;
    overflow: auto;
    border-radius: 2vh;

    /* Hide scrollbar for all browsers */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.feed-bottom::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Edge */
}


.post-card {
    width: 100%; 
    max-width: 40vw;
    min-width: 300px; 
    min-height: 130px;
    background-color: #fcd8b6;
    border-radius: 2%;
    border: 1.5px solid #8b4513;
    font-family: 'Nunito', sans-serif;
    color: #450b00;
    font-size: 15pt;
    overflow: hidden; /* Prevent scrolling within the card */
}

/* Make the username larger */
.post-card .username {
    font-size: 17pt; /* Larger font size for username */
    font-weight: bold; /* Optional: Make it bold */
    color: #450b00; /* Optional: Customize color */
}

/* Make the "Posted on:" part smaller */
.post-card .posted-on {
    font-size: 11pt; /* Smaller font size for "Posted on:" */
    color: rgb(10, 10, 10); /* Optional: Keep it light */
}

/* Keep the regular text the same */
.post-card .post-content {
    font-size: 14pt; /* Regular font size for post content */
    color: #450b00; /* Optional: Customize color */
    text-align: left; /* Align text to the left */
}

.post-card img {
    display: block; /* Removes inline spacing from images */
    max-width: 100%; /* Ensures the image fits the card width */
    height: auto; /* Maintains the image's aspect ratio */
    max-height: 350px;
    width: auto; /* Maintains aspect ratio when resizing */
    height: auto; /* Ensures aspect ratio is preserved */
    object-fit: contain;
    margin-top: 10px; /* Optional: Add some spacing around the image */
    border-radius: 5px; /* Optional: Add rounded corners to the image */
}

/* Pagination controls */
.pagination-controlss-profile {
    display: flex; /* Flexbox for layout */
    justify-content: space-between; /* Distribute buttons evenly */
    width: 100%; /* Full width */
}

.pagination-controlss-profile button {
    padding: 10px 20px; /* Button padding */
    background-color: #450b00; /* Dark brown background */
    color: white; /* White text */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Hand cursor */
}

.pagination-controlss-profile button:disabled {
    cursor: not-allowed; /* Prevent interaction */
    color: white; /* Keep text white */
    background-color: #450b00; /* Keep background consistent */
}

.dropdown-menu {
    position: fixed; /* Fixed to the viewport */
    top: 10% !important;
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Adjust only horizontally, remove vertical centering */
    background-color: #fcd8b6; /* Light background for the modal */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for depth */
    border-radius: 8px; /* Rounded corners */
    padding: 20px; /* Internal spacing */
    z-index: 10; /* Ensure it appears above other elements */
    display: flex;
    flex-direction: column; /* Stack buttons vertically */
    align-items: center; /* Center buttons horizontally */
    width: 300px; /* Set a fixed width for the modal */
    z-index: 1001; /* Ensure it's above other modal content */
    
}


/* Buttons inside the dropdown menu */
.dropdown-menu button {
    background-color: #450b00; /* Dark brown background */
    color: white; /* White text */
    border: none; /* Remove default border */
    border-radius: 5px; /* Rounded corners */
    padding: 10px 20px; /* Spacing for buttons */
    margin: 10px 0; /* Space between buttons */
    cursor: pointer; /* Hand cursor for buttons */
    font-size: 1rem; /* Adjust font size */
    text-align: center; /* Center text */
    width: 100%; /* Make buttons take full width of the modal */
}

/* Add hover effect for buttons */
.dropdown-menu button:hover {
    background-color: #5a1b00; /* Slightly darker brown on hover */
}

/* Optional: Add a subtle transition for hover effect */
.dropdown-menu button {
    transition: background-color 0.3s ease;
}

.following-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Black overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3000; /* Ensure it's above other modals */
}

.following-modal-content {
    background-color: #fcd8b6; /* Light tan background */
    color: #5a1b00; /* Dark brown text */
    padding: 20px;
    border-radius: 8px;
    border: 2px solid #450b00; /* Dark border for emphasis */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 90%;
    z-index: 3100; /* Ensure it's above the overlay */
    position: relative; /* For positioning the close button */
}

.following-modal-content h3 {
    color: #0c0b0b; /* Header color */
    font-weight: bold;
}

.following-modal-content ul {
    color: #5a1b00; /* Text in the list */
}

.close-button {
    position: absolute; /* Position within the modal */
    top: 10px; /* Adjust as needed */
    right: 10px; /* Adjust as needed */
    background: none; /* No background */
    border: none; /* No border */
    font-size: 1.5rem; /* Large enough to see clearly */
    font-weight: bold;
    color: #450b00; /* Dark brown for contrast */
    cursor: pointer;
}

.close-button:hover {
    color: #5a1b00; /* Slightly lighter color on hover */
}

.followers-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Black overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3000; /* Ensure it's above other modals */
}

.followers-modal-content {
    background-color: #fcd8b6; /* Light tan background */
    color: #5a1b00; /* Dark brown text */
    padding: 20px;
    border-radius: 8px;
    border: 2px solid #450b00; /* Dark border for emphasis */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 90%;
    z-index: 3100; /* Ensure it's above the overlay */
    position: relative; /* For positioning the close button */
}

.followers-modal-content h3 {
    color: #5a1b00; /* Header color */
    font-weight: bold;
}

.followers-modal-content ul {
    color: #5a1b00; /* Text in the list */
}

.following-modal-specific {
    background-color: #fcd8b6; /* Unique styles if needed */
}


.modal {
    position: fixed; /* Fixed to viewport */
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust for perfect centering */
    width: 100vw; /* Full viewport width */
    height: auto; /* Auto height */
    background: rgba(0, 0, 0, 0.5); /* Transparent black overlay */
    display: flex; /* Flexbox layout */
    align-items: center; /* Center content horizontally */
    justify-content: center; /* Center content vertically */
    z-index: 1000; /* Ensure it appears above all other elements */
}

.modal-content {
    background-color: #fff; /* White background */
    padding: 20px; /* Internal spacing */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    width: 400px; /* Fixed width */
    max-width: 100%; /* Responsive width */
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}
