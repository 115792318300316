@tailwind base;
@tailwind components;
@tailwind utilities;


html, body {
  margin: 0; 
  padding: 0; 
  width: 100%; 
  height: 100%; 
  overflow-x: hidden;
  
}

.App {
  background-color: hsl(36, 81%, 70%) !important;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  
}
/* #805E4D */
.navbar {
  width: 15%; 
  min-width: 350px;
  max-width: 400px;
  height: 100vh;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 5vh;
  border-right: 2px solid  hsl(26, 74%, 60%);
}
.navbar::before {
  width: 99.5%;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('images/navbar_sun.png'); 
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat; 
  filter: blur(4px); /* Apply blur here */
  z-index: 0; /* Place it behind the navbar content */
}


.navbar-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.navbar-title {
  font-family: 'Nunito', sans-serif;
  font-weight: bold;
  font-size: x-large !important;
  color: black;
}
.navbar-title-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.navbar-item {
  font-family: 'Nunito', sans-serif;
  color: #450b00;
  text-align: left;
  font-size: large;
}

.main-container {
  width: 100%;
  height: 100vh;
  position: fixed;
  margin-left: 0; 
}

.main-container.with-navbar {
  width: calc(100% - 15vw); 
  min-width: calc(100% - 350px);
  max-width: calc(100% - 400px);
  height: 100vh;
  position: fixed;
  margin-left: 15%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-link {
  color: #61dafb;
}