
.home-container {
    width: 100%;
    min-width: calc(100vw - 350px);
    max-width: calc(100vw - 400px);
    height: 100%;
    min-height: 100vh;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    margin-top: 3vh;
    padding: 2vh;
}

.search-box {
    width: 20vw;
    max-width: 20vw; 
    min-width: 200px;
    min-width: 400px;
    height: 10vh;
    min-height: 10vh;
    margin-left: 4vw;
}

.side-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    margin-right: 5vw;
    height: 100%;
    min-height: 100vh;
    width: 20vw;
    max-width: 20vw; 
    min-width: 200px;
    min-width: 400px;
    padding: 1vh;
}
.profilelist-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 52vh;
    min-height: 600px;
    max-height: 900px;
    width: 100%;
    max-width: 20vw; 
    min-width: 200px;
    min-width: 400px;
    background-color: #fcd8b6;
    margin-left: 4vw;
}
.user-card {
    width: 100%;
    max-width: 20vw; 
    min-width: 200px;
    height: 13vh;
    min-height: 150px;
    margin-top: 1vh;
    margin-bottom: 1vh;
    background-color: #fcd8b6;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 2vw;
}
.usercard-text {
    font-size: medium;
    font-weight: bold;
    color: #450b00;
}
.addfriend-button {
    background-color: #450b00;
    color: 'white';
    width: 3vw;
    min-width: 100px;
    max-width: 3vw;
}
.center-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    min-height: 100vh;
    width: 100%; 
    max-width: 40vw;
    min-width: 300px; 
    margin: 10px;
}

.feed-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    height: 10%;
    min-height: 10vh;
    width: 100%; 
    max-width: 40vw;
    min-width: 300px; 
    padding: 1vh;
}
.feed-bottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch; /* Ensure children match parent width */
    height: 80%;
    min-height: 80vh;
    width: 100%;
    max-width: 40vw;
    overflow: auto;
    border-radius: 2vh;
}
.post-card {
    width: 100%; 
    max-width: 40vw;
    min-width: 300px; 
    min-height: 130px;
    background-color: #fcd8b6;
    border-radius: 2%;
    border: 1.5px solid #8b4513;
    font-family: 'Nunito', sans-serif;
    color: #450b00;
    font-size: 15pt;
    overflow: hidden; /* Prevent scrolling within the card */
}

/* Make the username larger */
.post-card .username {
    font-size: 17pt; /* Larger font size for username */
    font-weight: bold; /* Optional: Make it bold */
    color: #450b00; /* Optional: Customize color */
}

/* Make the "Posted on:" part smaller */
.post-card .posted-on {
    font-size: 11pt; /* Smaller font size for "Posted on:" */
    color: rgb(10, 10, 10); /* Optional: Keep it light */
}

/* Keep the regular text the same */
.post-card .post-content {
    font-size: 14pt; /* Regular font size for post content */
    color: #450b00; /* Optional: Customize color */
    text-align: left; /* Align text to the left */
}


.post-card img {
    display: block; /* Removes inline spacing from images */
    max-width: 100%; /* Ensures the image fits the card width */
    height: auto; /* Maintains the image's aspect ratio */
    max-height: 350px;
    width: auto; /* Maintains aspect ratio when resizing */
    height: auto; /* Ensures aspect ratio is preserved */
    object-fit: contain;
    margin-top: 10px; /* Optional: Add some spacing around the image */
    border-radius: 5px; /* Optional: Add rounded corners to the image */
}

.feed-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch; /* Match parent width */
    width: 100%; 
    max-width: 100%; /* Match parent */
    min-width: 300px; 
}

.feed-type-container {
    display: flex;
    align-items: center;
    gap: 10px; /* Space between label and radio buttons */
}

.feed-type-label {
    font-family: 'Nunito', sans-serif;
    font-size: 16px; /* Slightly larger font size */
    font-weight: bold;
    color: #450b00;
}

.feed-settings label {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 16px; /* Slightly larger text */
    font-weight: 500;
    color: #450b00;
}

.feed-settings input[type='radio'] {
    width: 20px; /* Larger radio button */
    height: 20px; /* Larger radio button */
    margin-right: 8px; /* Space between radio button and text */
    cursor: pointer;
    accent-color: black; /* Changes selected button to black */
}

.charCountText {
    color: #808080;
    font-size: small;
    font-weight: 500;
}

.pagination-controlss {
    display: flex;
    justify-content: space-between;
    width: 100%; /* Ensure it stretches to container width */
    padding: 10px 0;
    margin-top: -20px;
    margin-bottom: 10px;
}

.pagination-controlss button {
    padding: 10px 20px;
    background-color: #450b00;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

.pagination-controlss button:disabled {
    cursor: not-allowed;
    color: white;
    background-color: #450b00;
}

