.comment-card {
    width: 40vw; 
    max-width: 40vw;
    min-width: 300px; 
    height: 25vh;
    min-height: 130px;
    background-color: #fcd8b6;
    border-radius: 0%;
    border: 1px solid  hsl(0, 42%, 70%);
    font-family: 'Nunito', sans-serif;
    color: #450b00;
    font-size: 14pt;
}

.selectedpost-card {
    width: 100%; 
    max-width: 40vw;
    min-width: 300px; 
    height: 25vh;
    min-height: 130px;
    background-color: #fcd8b6;
    border-radius: 0%;
    border: 1px solid  hsl(0, 42%, 70%);
    font-family: 'Nunito', sans-serif;
    color: #450b00;
    font-size: 14pt;
}

.comments-section {
    width: 100%; 
    max-width: 40vw;
    min-width: 300px; 
    height: 60vh;
    min-height: 60vh;
    max-height: 60vh;
}

.username {
    font-size: 17pt; /* Larger font size for username */
    font-weight: bold; /* Optional: Make it bold */
    color: #450b00;
}